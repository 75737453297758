.header {
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    height: 50px;
    padding: 15px 35px;
}

.userPanel {
    column-gap: 15px;
    display: flex;
    margin-right: 15px;
}

.customerContainer {
    align-self: center;
}

.user {
    align-self: center;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 5px;
    margin-top: 8px;
}

.logOut {
    align-self: center;
    cursor: pointer;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-top: 5px;
    text-decoration: underline;
}

.options {
    display: flex;
    margin: 15px 25px;
}

.hamburger {
    align-self: center;
    height: 25px;
    user-select: none;
    width: 25px;
}

.breadcrumbs {
    margin-left: 250px;
}

@media (max-width: 1024px) {
    .header {
        padding: 15px 0px;
        column-gap: 80px;
    }
}

@media (max-width: 910px) {
    .header {
        column-gap: 80px;
        padding: 15px 15px 15px 10px;
    }
    .userPanel {
        margin-right: 0px;
    }
}

@media (max-width: 549px) {
    .userPanel {
        column-gap: 0;
    }
}

@media (max-width: 364px) {
    .header {
        column-gap: 30px;
    }
}
