.container {
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 5px;
    position: relative;
}

.label {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 10px;
    margin-left: 10px;
}

.input {
    font-size: 14px;
    font-family: Mark-Pro, sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    min-height: 35px;
    outline: 0;
    text-indent:8px;
}

.input::placeholder {
    font-family: Mark-Pro, sans-serif;
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
}

.input:focus::placeholder {
    color:transparent;
}

.input:-webkit-autofill,
.input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}

.selectedValue {
    padding: 20px;
}

.required {
    color: red;
}

.error {
    border: 1px solid red;
}

.errorMessage {
    color: red;
    margin: 5px;
    text-transform: uppercase;
}

@media (max-width: 280px) {
    .input {
        min-width: 150px;
        width: 75%;
    }
}

