.container {
    display: flex;
    flex-direction: column;
    padding: 5px;
    position: relative;
    user-select: none;
}

.selectInput {
    min-height: 40px;
    text-transform: capitalize;
}

.selectInput ul {
    background-color: white;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 1;
}

.selectInput li {
    padding: 10px;
}

.selectInput li:hover {
    background-color: rgba(147, 150, 159, 0.3);
    border-radius: 5px;
}

.selectedValue {
    display: flex;
    justify-content: space-between;
}

.logoutContainer {
    align-self: center;
    margin-right: -15px;
}

.logout {
    cursor: pointer;
    height: 20px;
    margin-left: 10px;
    width: 20px;
}

.value {
    align-self: center;
    font-style: normal;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-left: 5px;
}

.arrowUp {
    margin: 17px;
    width: 15px;
    rotate: 180deg;
}

.arrowDown {
    margin: 17px;
    width: 15px;
}

.border {
    background-color: #FFFFFF;
    border: 1px solid #0000001f;
    border-radius: 5px;
}

.dropdownContainer {
    align-items: flex-start;
    background: #FFFFFF;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    user-select: none;
}
