.row {
    background-color: #F5F7FA;
}

.row td:first-child {
    border-top-left-radius: 6px;
}

.row td:last-child {
    border-top-right-radius: 6px;
}

.border {
    border-bottom: 1px solid #d6ebe3
}

.radiusBorder {
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.12);
    border-radius: 6px;
}

.description {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 10px 0;
    width: 20%;
}

.arrow {
    text-align: center;
    padding-left: 15px;
}

.column {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 10px 0;
    vertical-align: top;
}

.numberColumn {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 10px 0;
    text-align: right;
    vertical-align: top;
}

.expandableColumn {
    padding: 5px 0;
    text-align: right;
    width: 5%;
}

.expandedTd {
    padding: 0;
}

.expandedHeading {
    background-color: #F5F7FA;
    color: #7783A0;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.17px;
    text-align: right;
}

.expandedValue {
    text-align: right;
}

.expandedColumn {
    margin-right: 5px;
    padding-left: 10px;
}

.expandedSection {
    background-color: #F5F7FA;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.12);
    display: flex;
    justify-content: space-between;
}

.totalTitle {
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    padding: 10px 0;
}

.totalNumber {
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    padding: 10px 0;
    text-align: right;
    vertical-align: top;
}

.noData {
    background-color: #FFFFFF;
    margin: 20px 40px 30px 10px;
    padding: 28px 30px;
}

@media (max-width: 1024px) and (min-width: 550px){
    .noData {
        margin: 20px 10px;
    }
}

@media (max-width: 549px) {
    .noData {
        margin: 20px 0px;
    }
}
