.table {
    border-collapse: collapse;
    width: 100%;
}

.header {
    border-bottom: 1px solid #d6ebe3;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    vertical-align: top;
}

.textHeader {
    text-align: left;
}

.numberHeader {
    text-align: right;
}

tr th:not(:first-child):not(:last-child),td:not(:first-child):not(:last-child) {
    padding-right: 10px;
}
