.container {
    align-content: center;
    background-color: #F33A3B;
    color: #FFF;
    display: flex;
    height: 50px;
    justify-content: center;
    left: 0;
    margin-bottom: 15px;
    position: absolute;
    top: 70px;
    width: 100%;
}

.outlined {
    border: 3px solid #F33A3B;
    color: #F33A3B;
    margin: 30px;
    padding: 15px;
    text-align: center;
    width: 350px;
}

.logo {
    align-self: center;
    height: 25px;
    margin: 10px;
    width: 25px;
}

.text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
}

@media (max-width: 549px) {
    .container {
        top: 70px;
    }
}
