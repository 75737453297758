.button {
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-family: Mark-Pro, sans-serif;
    font-style: normal;
    font-weight: 500;
    height: 40px;
    line-height: 18px;
    width: 100px;
}

.default {
    background-color: #21315D;
    color: white;
}

.outline {
    border: 1px solid black;
    color: black;
}
