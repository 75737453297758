.container {
    background-color: #FFFFFF;
    margin: 20px 40px 30px 10px;
    padding: 28px 30px;
}

.title {
    color: #21315D;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 25px;
    line-height: 30px;
}

@media (max-width: 1024px) and (min-width: 550px){
    .container {
        margin: 20px 10px;
    }
}

@media (max-width: 549px) {
    .container {
        margin: 20px 0px;
    }
}
