.container {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding-top: 50px;
}

.title {
    font-family: Mark-Pro-Light, sans-serif;
    font-weight: 350;
    font-size: 40px;
    font-style: normal;
    line-height: 44px;
}

.input {
    background-color: #FFFFFF;
    padding: 50px;
    width: 35%;
}

.button {
    margin: 15px 5px;
}

.error {
    color: #F33A3B;
}
