.container {
    display: flex;
    flex-direction: column;
    padding: 5px;
    position: relative;
    user-select: none;
}

.selectInput {
    min-height: 35px;
    outline: 0;
}

.selectInput ul {
    background-color: white;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 1;
}

.selectInput li {
    padding: 10px;
}

.selectInput li:hover {
    background-color: rgba(197, 197, 197, 0.5);
}

.selectedValue {
    cursor: pointer;
    display: flex;
}

.value {
    font-size: 17px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    margin: 12px -10px 10px 10px;
}

.arrowContainer {
    cursor: pointer;
}

.arrowUp {
    margin: 17px;
    width: 15px;
    rotate: 180deg;
}

.arrowDown {
    margin: 17px;
    width: 15px;
}

.border {
    background-color: #FFFFFF;
    border: 1px solid #0000001f;
    border-radius: 5px;
}

@media (max-width: 549px) {
    .selectInput {
        width: 65px;
    }
}

