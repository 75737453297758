.loader {
    animation: load8 1.1s infinite linear;
    border: 17.6px solid rgba(0, 0, 0, 0.2);
    border-left: 17.6px solid #F33A3B;
    border-radius: 50%;
    height: 96px;
    left: calc(50% - 64px);
    position: absolute;
    top: 50%;
    transition: opacity 0.3s;
    width: 96px;
}

@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
