.companyLogo {
    align-items: center;
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}

.companyLogo img {
    height: 35px;
    margin-right: 10px;
}

.companyName {
    color: #1D3160;
    font-family: sans-serif;
    font-weight: 700;
    text-decoration: none;
}

@media (max-width: 910px) {
    .companyLogo {
        margin-left: 0;
    }
}

@media (max-width: 795px) {
    .companyLogo {
        margin-top: -8px;
    }
}

@media (max-width: 549px) {
    .companyLogo {
        margin-top: -15px;
    }
}
