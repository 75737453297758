.container {
    display: flex;
    flex-direction: column;
}

.navigation {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    margin: 50px;
    padding-left: 20px;
    width: 305px;
    padding-top: 28px;
    padding-bottom: 30px;
}

.navLink {
    align-items: center;
    color: #21315D;
    display: flex;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.01em;
    margin: 10px 0;
    text-decoration: none;
}

.activeLink {
    font-weight: 700;
}

.activeLink .circle {
    background-color: #D6EBE3;
}

.circle {
    border-radius: 22px;
    background-color: #EBF5F1;
    width: 45px;
    text-align: center;
    height: 45px;
}

.icon {
    height: 30px;
    padding: 7px;
    width: 30px;
}

.text {
    margin: 10px;
}

@media (max-width: 1024px) {
    .navigation {
        margin: 50px 15px;
        padding: 15px;
    }
}

@media (max-width: 910px) {
    .container {
        display: none;
    }

    .navigation {
        margin: 15px 10px;
        width: 200px;
    }

    .showContainer {
        align-items: flex-start;
        background-color: #EBF5F1;
        display: flex;
        flex-direction: column;
        height: 100%;
        left: 0;
        overflow-x: hidden;
        padding-top: 24px;
        position: fixed;
        top: 80px;
        width: 250px;
        z-index: 1;
    }
}
